import React, {useState} from 'react'
import {RiMenu3Line, RiCloseLin, RiCloseLine, RiMenLine} from 'react-icons/ri'
import logo from '../../assets/LogoMPC.svg';
import './Navbar.css'


// BEM 
const Menu = () => (
    <>
    <p><a href="#What">Sobre mí</a></p>
    <p><a href="#Contri">Contribuciones</a></p>
    <p><a href="#Software">Dominios</a></p>
    <p><a href="#Contact">Contacto</a></p>
    </>
)

const Navbar = () => {
    const [toggleMenu, setToggleMenu] = useState(false);
    return (
        <div className="__navbar">
            <div className="__navbar-links">
                <div className="__navbar-links_logo">
                <svg width="80" height="90" viewBox="0 0 395 445" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="LogoSent" d="M197.5 0L337.725 64.5225V23.2131H247.948L234.531 17.0394H337.725H344.638V67.7032L395 90.8768L384.138 95.7935L201.45 180.766V421.787H194.538V180.766L10.8625 95.7935L194.538 421.787H201.45L384.138 95.7935L395 90.8768L344.638 182.248V445H323.9V436.604H337.725V194.595L222.188 398.574V445H172.813V398.574L57.275 194.595V436.604H77.025V445H50.3625V182.248L0 90.8768L50.3625 67.7032V16.2986H57.275L160.469 17.0394L147.052 23.2131H57.275V64.5225L197.5 0Z" fill="white"/>
            </svg>
                </div>
                <div className="__navbar-links_container">
                    <Menu />
                </div>
            </div>

            <div className="__navbar-menu">
                {toggleMenu
                ? <RiCloseLine color='#fff' size={27} onClick={() => setToggleMenu(false)} />
                : <RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true)} />
                }
                { toggleMenu && (
                    <div className='__navbar-menu_container scale-up'>
                        <div className='__navbar-menu_container-links'>
                            <Menu />

                        </div>
                    </div>
                )

                }
            </div>
        </div>
    )
}

export default Navbar
