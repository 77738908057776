import React from 'react';
import './Footer.css';
import logo from '../../assets/MPCLogo.svg';
import {Form} from '../../components';

export const Footer = () => {
    return (
        <div className='bg_layer'>
            <div className='__footer section__padding' id='Contact'>
                
                <div className='__footer-heading'>
                    <h1>¿Te convencí ?</h1>
                </div>

                <div className='__form-container'>
                    
                <Form img={logo} title="Contáctame" mail="mauperezcar@gmail.com" btn_text="Enviar"></Form>

                </div>

                <div className='__footer-copyright'>
                    <p>@ 2022 Mauricio Pérez Cárdenas. Todos los derechos reservados..</p>
                </div>
            </div>
            
        </div>
    )
}

export default Footer
