import React from 'react'
import './CTA.css'
import {Apps} from '../../components';
import { logo31, logo32, logo33, logo34} from '../../containers/features/imports';

const CTA = () => {
    return (
        <div className='__cta'>
            <div className='__cta-content'>
                <h3>Actualmente estoy aprendiendo:</h3>
            </div>
            <div className='__cta-apps'>
                <Apps ima={logo31} name="Unreal Engine" info=""></Apps>
                <Apps ima={logo32} name="Cinema 4D" info=""></Apps>
                <Apps ima={logo33} name="Blender" info=""></Apps>
                <Apps ima={logo34} name="Spark AR" info=""></Apps>
            </div>
        </div>
    )
}

export default CTA
