import React from 'react';
import './Article.css';

export const Article = ({imgURL,title, sub, info, link, btn_text, bg}) => {

    const styles ={
        bgimg: {
            background: "url("+bg+")",
            size: "cover",  
        }
    };
       
    return (

        

        <div className='__blog-container_article' style={styles.bgimg}>
            <div className='__blog-container_article-circle'></div>
            
            <div className='__blog-container_article-content'>
                <h2 className='gradient__text2'>{title}</h2>
                <h5>{sub} </h5>
                <p>{info}</p>

                {btn_text != "nose" &&
                    <a href={link} className='' target="_blank">{btn_text}</a>
                }
                
            </div>
            
            <img src={imgURL} alt="" />

            {/* 
            <div className='__blog-container_article-image'>
                <img src={imgURL} alt="blog" />
            </div>
            <div className='__blog-container_article-content'>
                <div>
                    <p> {date} </p>
                    <h3> {title} </h3>
                </div>
                <p>Read full article</p>
            </div>
            */}
        </div>
    )
}



export default Article
