import React, { Component } from 'react';
import './Modal.css';
import Portal from '../../Portal';

export default class Modal extends Component {
  render() {

    const{children, toogle, active} = this.props;

    return (
            <Portal>
                {active && (
                    <div className='modal-container'>
                        <div className='modal-window'>
                            <button className='modal-close' onClick={toogle} >X</button>
                            <div>
                                {children}
                            </div>
                        </div>
                        <div onClick={toogle} className='modal-bg'>
                            
                        </div>
                    </div>
                )}
            </Portal>
        )
  }
}
