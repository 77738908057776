import React from 'react';
import './D3.css';

export const D3 = ({imgURL,title, sub, info, link, btn_text}) => {
    return (

    <div className='__3d-iframe'>
        <iframe src='https://my.spline.design/portfolio-5cdf507f87b2995e5de54b23c447bac0/' frameborder='0' width='100%' height='100%'></iframe>
    </div>

    )
}

export default D3
