import React from 'react';
import {Article} from '../../components';
import {blog01, blog02, blog03, blog04, blog05, blog06, blog07, blog08, blog09} from './imports';
import './Blog.css';

export const Blog = () => {
    return (
        <div className='__blog section__padding' id='Contri'>
            <div className='__blog-heading'>
                <h1 className='gradient__text'>Te presento mi trabajo</h1>
            </div>
            <div className='__blog-container'>

                {/*
                <div className='__blog-container_groupA'>
                    <Article imgURL={blog01} date="Sep 16, 2021" title="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Facere commodi." />
                </div>
                */}

                <div className='__blog-container_groupB'>
                    {/* 
                    <Article imgURL={blog02} date="Sep 16, 2021" title="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Facere commodi." />
                    <Article imgURL={blog03} date="Sep 16, 2021" title="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Facere commodi." />
                    <Article imgURL={blog04} date="Sep 16, 2021" title="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Facere commodi." />
                    <Article imgURL={blog05} date="Sep 16, 2021" title="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Facere commodi." />
                    <Article imgURL={blog05} date="Sep 16, 2021" title="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Facere commodi." />
                    <Article imgURL={blog05} date="Sep 16, 2021" title="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Facere commodi." />
                    */
                    }

                    <Article imgURL={blog01} title="JNV WESTERN" sub="E-commerce Developer" info="Desarrollo de tienda virtual para venta de ropa." link="https://jnvwesternwear.com/" btn_text="Visitar"
                    bg="https://images.unsplash.com/photo-1525814230241-7f78c608c54c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80"/>
                    <Article imgURL={blog02} title="PRESTIGE ENGLISH SCHOOL" sub="Front-End Developer" info="Colaborador en la parte de diseño y desarrollo de la web en sistema para gestion de alumnos." link="https://prestige-english.com/" btn_text="Visitar"
                    bg="https://images.pexels.com/photos/442420/books-shelves-architecture-wood-442420.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"/>
                    <Article imgURL={blog03} title="PEERTECH" sub="Marketing y Full-Stack Developer" info="Colaborador secundario en campañas de Marketing. Principal en Diseño    multimedia y creacion de contenido." link="https://www.peertech.mx/" btn_text="Visitar"
                    bg="https://images.unsplash.com/photo-1591405351990-4726e331f141?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"/>
                    <Article imgURL={blog04} title="GRUPO DE ORO" sub="Becario" info="Reparación de equipo de computo, mantenimiento de software, capacitación de software para un punto de venta utilizado por los empleados." link="#" btn_text="nose"
                    bg="https://images.pexels.com/photos/443383/pexels-photo-443383.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"/>
                    <Article imgURL={blog06} title="ITM Morelia" sub="Front-End Developer" info={"Creación de aplicación web para la gestión de tramites de mi universidad."} link="https://dep.morelia.tecnm.mx/" btn_text="Visitar"
                    bg="https://images.pexels.com/photos/586570/pexels-photo-586570.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"/>
                    <Article imgURL={blog07} title="Mau Store" sub="Front-End Developer" info={"Ecommerce concept portfolio para Cantera digital, Morelia Mich."} link="https://mauriciop23.github.io/cdigital-ecommerce/" btn_text="Visitar"
                    bg="https://images.unsplash.com/photo-1441986300917-64674bd600d8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"/>
                    <Article imgURL={blog05} title="AR APP" sub="Full-Stack Developer" info={"Aplicación de realidad aumentada para el estudio del sistema circulatorio."} link="https://www.facebook.com/100002252673381/videos/403041491894201/" btn_text="Visitar"
                    bg="https://images.unsplash.com/photo-1567881248445-83ead68a44d8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80"/>
                    <Article imgURL={blog06} title="Objs. de Aprendizaje" sub="Front-End Developer" info={"Repositorio para objetos de aprendizaje en el instituto tecnológico de Morelia."} link="https://www.objetos-aprendizaje-tecnm.mx/" btn_text="Visitar"
                    bg="https://images.unsplash.com/photo-1568667256549-094345857637?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=715&q=80"/>
                    <Article imgURL={blog08} title="Asistente Inteligente" sub="Product Owner" info={"Proyecto para InnovaTecNM 2022. 1er lugar regional, 4to lugar Nacional."} link="#" btn_text="nose"
                    bg="https://images.unsplash.com/photo-1488590528505-98d2b5aba04b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"/>
                    <Article imgURL={blog09} title="PIDECO" sub="UX/IU - FrontEnd Developer" info={"Plataforma para el apoyo de cooperativas en el estadio de Michoacán. (En desarrollo)"} link="https://www.figma.com/file/kRLxeJe27YSyTft2mxkNmR/SEI-Mujer?node-id=71%3A1847" btn_text="nose"
                    bg="https://images.unsplash.com/photo-1497032628192-86f99bcd76bc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"/>
                    

                    
                </div>
            </div>
        </div>
    )
}

export default Blog
