import React from 'react';
import {Feature} from '../../components'
import {Apps} from '../../components';
import './Features.css';
import { logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8, logo9, logo10, logo11, logo12, logo13, logo14, logo15, logo16, logo17, logo18, logo19, logo20, logo21, logo22, logo23, logo24, logo25, logo26, logo27, logo28, logo29, logo30
} from './imports';

const featuresData=[


    {
        title: 'Disfruto aprender tecnologías y herramientas nuevas',
    },

    {
        title: 'Expande mi creatividad y complementa ideas para mis proyectos',
    },

    {
        title: 'Me preparo para obtener certificaciones que avalen mis conocimientos',
    }

]

const Features = () => {
    return (
        <div className='__features section__padding' id="Software">
            <div className='__features-heading'>
                <h1 className='gradient__text'>En constante aprendizaje</h1>
                <p></p>
                {featuresData.map((item, index) => (
                    <Feature title={item.title} text= {item.text} key={item.title + index} />
                ))}
            </div>
            <div className='__features-container'>
                <div className='__apps-container'>
                    <Apps ima={logo1} name="Java" info=""></Apps>
                    <Apps ima={logo2} name="Python" info=""></Apps>
                    <Apps ima={logo3} name="HTML 5" info=""></Apps>
                    <Apps ima={logo4} name="CSS" info=""></Apps>
                    <Apps ima={logo5} name="JavaScript" info=""></Apps>
                    <Apps ima={logo6} name="React" info=""></Apps>
                    <Apps ima={logo7} name="Django" info=""></Apps>
                    <Apps ima={logo8} name="Kotlin" info=""></Apps>
                    <Apps ima={logo9} name="MySQL" info=""></Apps>
                    <Apps ima={logo10} name="Oracle SQL" info=""></Apps>
                    <Apps ima={logo11} name="SQL Server" info=""></Apps>
                    <Apps ima={logo12} name="Postgres" info=""></Apps>
                    <Apps ima={logo13} name="Wordpress" info=""></Apps>
                    <Apps ima={logo14} name="Maya" info=""></Apps>
                    <Apps ima={logo15} name="Figma" info=""></Apps>
                    <Apps ima={logo16} name="Spline" info=""></Apps>
                    <Apps ima={logo17} name="Unity" info=""></Apps>
                    <Apps ima={logo18} name="Android Srudio" info=""></Apps>
                    <Apps ima={logo19} name="Elementor" info=""></Apps>
                    <Apps ima={logo20} name="Xampp" info=""></Apps>
                    <Apps ima={logo21} name="Photoshop" info=""></Apps>
                    <Apps ima={logo22} name="Lightroom" info=""></Apps>
                    <Apps ima={logo23} name="Ilustrador" info=""></Apps>
                    <Apps ima={logo24} name="VSCO" info=""></Apps>
                    <Apps ima={logo25} name="Picsart" info=""></Apps>
                    <Apps ima={logo26} name="Canva" info=""></Apps>
                    <Apps ima={logo27} name="Filmora" info=""></Apps>
                    <Apps ima={logo28} name="FL Studio" info=""></Apps>
                    <Apps ima={logo29} name="Office" info=""></Apps>
                    <Apps ima={logo30} name="Vuforia" info=""></Apps>

                </div>
            </div>
        </div>
    )
}

export default Features
