import React from 'react'

import { Article, Brand, CTA, Feature, Navbar, D3, Apps, Form, Modal} from './components'
import { Blog, Features, Footer, Header, Possibility, What } from './containers'
import './App.css';


const App = () => {


    return (
        <div className="App">
            <div className="gradient__bg">
                <Navbar/>
                <Header/>
            </div>
            <What/> 
            <Blog/>
            <Features/>
            <CTA/>
            <Footer/>
        </div>
        
    )
}

export default App
