import React from 'react';
import './Apps.css';

export const Apps = ({ima,name, info}) => {
    return (


        <div className='__Apps-card'>
            <div className='__App-cad-cont'>
                <div className='card-img'>
                <img loading="lazy" src={ima} alt={name} />
                </div>
                <div className='card-body'>

                    <div className='card-content'>
                        
                        <h3> {name} </h3>
                        <p> {info} </p>
                    </div>

                    <div className='wave wave3'></div>
                    <div className='wave wave1'></div>
                    
                </div>
            </div>
            
            
        </div>
    )
}

export default Apps
