import React from 'react'
import './Feature.css'

const Feature = ({title, text, list, l1, l2, l3, l4, l5, modal, modalcomponent, toogle}) => {
    return (
        <div className='__features-container__feature'>
            <div className='__features-container__feature-title'>
                <div />
                <h2>{title}</h2>
                
            </div>
            <div className='__features-container_feature-text'>
                <p>{text}</p>                
            </div>

            {list == "si" &&
                    <div className='__features-container_feature-list'>
                        <ul>
                            <li><a href="https://www.youtube.com/watch?v=hfrXmKg5xKw&list=OLAK5uy_kLlpzRsc1GRlT8ymfzXLrQUS17jPeQSgU&index=2" target="_blank"> {l1} </a></li>
                            <li><a href="https://youtu.be/TZQnV2HCW68?list=OLAK5uy_mebYgGjMINiO11XqdRUSOHf7i30KuJ4Ns&t=73" target="_blank"> {l2} </a></li>
                            <li><a href="https://youtu.be/HLetFEpgPgY?t=54" target="_blank"> {l3} </a></li>
                            <li><a href="https://youtu.be/5LRwYKpV-6A?t=60" target="_blank"> {l4} </a></li>
                            <li>Y a veces<a href="https://www.youtube.com/watch?v=dB_OgRNYxaU"> {l5} </a></li>
                        </ul>             
                    </div>
                }

            {modal == "si" && (
                

                <div className='__header-content__input'>
                    <button className='Modal-btn' onClick={toogle}>Ver Fotos</button>    
                </div>

                )  
            }
            
{/*
 
            <div className='__features-container_feature-list'>
                <ul>
                        <li>{list}</li>
                </ul>
            </div>
 */}
            
        </div>
    )
}

export default Feature
