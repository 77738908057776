import React, {useState} from 'react';
import {Feature} from '../../components';
import {Modal} from '../../components';
/*
import {Instafeed} from '../../components/insta.js';
*/
import './What.css';

export const What = () => {
    
    const [active, setActive] = useState(false);

    const  toogle = () => {
        setActive(!active)
    }
    
    

    return (
        
        <div className='__what section__margin' id='What'>
            

            <div className='__what-heading'>
                <h1 className='gradient__text'>¡Hola! </h1> <span className='__what-span'>😄</span>
            </div>
            <div className='__what-feature'>
                <Feature text="Soy Mauricio, y desde 2018 soy estudiante del Instituto Tecnológico de Morelia. Desde pequeño he tenido una enorme atracción por la tecnología, y a lo largo de mi formación educativa, y ahora profesional, he contribuido en diversos proyectos que me han ayudado a desarrollar habilidades en varios campos, desde la creación de aplicaciones para escritorio, desarrollo e implementación de páginas y aplicaciones web, campañas para E-Commerce, hasta la obtención de reconocimientos en concursos de programación y ferias de proyectos a nivel nacional.
Sin embargo, durante todo este trayecto he encontrado un gusto particular por diseñar interfaces cautivadoras y experiencias de usuario agradables dentro de mis sistemas, además de poder darle vida a mis ideas desarrollándolas con diferentes herramientas y tecnologías que así me lo permiten.
" />
            </div>
            <div className='__what-heading'>
                <h1 className='gradient__text'>¿En qué invierto mi tiempo?</h1>
            </div>

            <Modal active={active} toogle={toogle}>
                <div className='Insta-container'>
                    <a href="https://www.instagram.com/mauricio_perez23/" target="_blank" rel="noreferrer"><h1 className='gradient__text'>Instagram</h1></a>
                    <hr/> <br />

                    <script src="https://snapwidget.com/js/snapwidget.js"></script>
                    <div className='Insta-content'>
                        <iframe className='insta-feed' title="Instafeed" src="https://snapwidget.com/embed/981966" class="snapwidget-widget" allowtransparency="true" frameborder="0" scrolling="si"></iframe>
                    </div>

                </div>
                

            </Modal>

            <div className='__what-container'>
                <Feature title="Música" text="Adoro escuchar música porque me ayuda a liberar mis ideas. Mis artistas favoritos son:" list="si" l1="Twenty One Pilots" l2="Flume" l3="Joji" l4="Ludovico Einaudi" l5="Los Cadetes de Linares" />
                <Feature title="Fotografia" text="Uno de mis pasatiempos favoritos es tomar fotografías." modal="si"  toogle={toogle}/>
                <Feature title="Aprender" text="Me gusta aprender cosas constantemente.
Cuando se me ocurre un proyecto usualmente necesito aprender algo nuevo, así que veo obligado a estudiar lo que hace falta, y casi siempre sale bien." />
            </div>
            
            <div className='wave wave1'></div>
            <div className='wave wave2'></div>
            <div className='wave wave3'></div>

        </div>
    )
        

}

export default What
