import React from 'react';
import './Header.css';
import {D3} from '../../components'
import mau from '../../assets/Mau.png';

export const Header = () => {
    return (
        <div className='__header section__padding' id='home'>
            <div className='__header-content'>
                <h1 className='gradient__text'>Mauricio</h1>
                <h1 className='gradient__text'>Pérez Cárdenas</h1>
                <p>Estudiante de ingeniería en sistemas computacionales</p>
                
                <div className='__header-content__input'>
                    <button type='button'>
                        <a href='/Docs/CV_Mauricio_PC.pdf' download> Descargar CV </a>
                    </button>
                </div>


            </div>
            
            <div className='__header-3dcontainer'>
                <div className='__header-image'>
                    <img src={mau} alt="mau" />
                </div>
                <D3/>
                <div className='__header-fade'></div>
            </div>
            
            
            
        </div>
    )
}

export default Header
